import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useLoadingStore } from "store/store";
import ProjectDataService from "services/project.service";
import { DashboardLayout } from "components/layouts/dashboard";
import InvoicesOverview from "pages-sections/invoices-overview";
import TicketsOverview from "pages-sections/tickets-overview";

export default function Home() {
  const [projects, setProjects] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [cancellations, setCancellations] = useState([]);
  let { projectId = "all" } = useParams();

  function filterCancellations(invoices) {
    const cancelledInvoices = [];
    const filteredInvoices = invoices.filter((invoice) => {
      if (invoice.cancellation) {
        cancelledInvoices.push(invoice);
        return false;
      }

      if (invoice.invoiceType === "cancellation") {
        return false;
      }

      return true;
    });
    setInvoices(
      filteredInvoices.sort(function (a, b) {
        if (a.number.toLowerCase() < b.number.toLowerCase()) return 1;
        if (a.number.toLowerCase() > b.number.toLowerCase()) return -1;
        return 0;
      })
    );
    setCancellations(
      cancelledInvoices.sort(function (a, b) {
        if (a.number.toLowerCase() < b.number.toLowerCase()) return 1;
        if (a.number.toLowerCase() > b.number.toLowerCase()) return -1;
        return 0;
      })
    );
  }

  useEffect(() => {
    useLoadingStore.setState({ isLoading: true });
    ProjectDataService.getAll(true).then((response) => {
      setProjects(response.data.projects);
    });
  }, []);

  useEffect(() => {
    useLoadingStore.setState({ isLoading: true });
    ProjectDataService.getOverview(projectId).then((response) => {
      filterCancellations(response.data.invoices);
      setTickets(response.data.tickets);
      useLoadingStore.setState({ isLoading: false });
    });
  }, [projectId]);

  return (
    <DashboardLayout projects={projects}>
      {projectId === "all" ? (
        <>
          <TicketsOverview
            project={projectId}
            tickets={tickets}
            title="Tickets"
          />
          <InvoicesOverview
            invoices={invoices}
            title="Rechnungen"
            invoiceType="RE"
          />
          <InvoicesOverview
            invoices={cancellations}
            title="Stornos"
            invoiceType="SR"
          />
        </>
      ) : (
        <>
          <TicketsOverview
            project={projectId}
            tickets={tickets}
            title="Tickets"
          />
          <InvoicesOverview
            invoices={invoices}
            title="Rechnungen"
            invoiceType="RE"
          />
          <InvoicesOverview
            invoices={cancellations}
            title="Stornos"
            invoiceType="SR"
          />
        </>
      )}
    </DashboardLayout>
  );
}
